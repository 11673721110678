import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import { container } from "assets/jss/material-kit-pro-react.js";
import Title from "./Title";
import NeedHelp from "./NeedHelp";

const pageStyle = theme => ({
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    textAlign: "center",
    lineHeight: "1.9rem",
    fontWeight: 200,
    ...container
  },
  timesImage: {
    margin: "30px auto 0px auto",
    maxWidth: "600px"
  },
  message: {
    fontSize: "1.25rem",
    fontWeight: 400,
    margin: "40px 0 5px 0",
  },
  buttonHolder: {
    margin: "20px 0 70px 0",
    textAlign: "center"
  },
  button: {
    color: "#000 !important",
    backgroundColor: "#fff",
    border: "solid 5px #D1833E",
    padding: "7px 25px",
    fontSize: "1.8rem",
    fontWeight: 300,
    letterSpacing: "4px",
    textTransform: "none",
  },
});

class Welcome extends React.Component {
  render() {
    const { classes } = this.props;
    if(!this.props.shouldShow) {
      return "";
    } else {
      return (
        <StaticQuery query={query} render={data => (
          <div className={classes.container}>
            <GridContainer justify="center" alignItems="center">
              <GridItem xs={12}>
                <Title showDates={true} />
              </GridItem>
              <GridItem xs={12}>
                <Img className={classes.timesImage} alt="" fluid={data.timesImage.childImageSharp.fluid} />
              </GridItem>
              <GridItem xs={12}>
                <div className={classes.message}>
                  Welcome! You're in the right place.<br/>
                  Click the button below to prepare for the event.
                </div>
              </GridItem>
              <GridItem xs={12}>
                <div className={classes.buttonHolder}>
                  <Button className={classes.button} size="lg" onClick={this.props.onShowCheckIn}>Check-In</Button>
                </div>
              </GridItem>
            </GridContainer>
            <NeedHelp />
          </div>
        )} />
      );
    }
  }
}

Welcome.propTypes = {
  classes: PropTypes.object.isRequired,
  shouldShow: PropTypes.bool.isRequired,
  onShowCheckIn: PropTypes.func
};

export default withStyles(pageStyle)(Welcome);

export const query = graphql`
  query {
    timesImage: file(relativePath: { eq: "times.png" }, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`