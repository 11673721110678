import React from "react";
import PropTypes from "prop-types";
import { Box, withStyles } from "@material-ui/core";
import { StaticQuery, graphql } from "gatsby";

import { completePreWatch } from "../../services/auth";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import zoomButton from "assets/img/zoom-button.png";

import { container } from "assets/jss/material-kit-pro-react.js";
import VimeoEmbed from "../VimeoEmbed/VimeoEmbed";
import NeedHelp from "./NeedHelp";

const pageStyle = theme => ({
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    textAlign: "center",
    lineHeight: "1.9rem",
    fontWeight: 200,
    ...container
  },
  step: {
    fontSize: "1.3rem",
    fontWeight: 400,
    margin: "10px 0 10px 0",
    "&:first-line": {
      color: "#D1833E",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.4rem",
      lineHeight: "1.8rem",
    }
  },
  caption: {
    fontSize: "1.0rem",
    fontWeight: 400,
    paddingTop: "5px"
  },
  fullScreenIcon: {
    color: "#ffffff",
    marginBottom: "-5px"
  },
  themeTitle: {
    fontSize: "1.9rem",
    fontWeight: 600,
    margin: "10px 0 5px 0",
  },
  zoomButtonHolder: {
    margin: "0 0 0 0",
    textAlign: "center"
  },
  zoomButton: {
    color: "#fff !important",
    backgroundColor: "transparent",
    border: "none",
    padding: "0",
    margin: "0",
    fontSize: "1.7rem",
    fontWeight: 300,
    letterSpacing: "4px",
    textTransform: "uppercase",
    boxShadow: "none",
    "&:hover,&:focus,&:visited": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  buttonHolder: {
    margin: "5px 0 35px 0",
    textAlign: "center"
  },
  button: {
    color: "#000 !important",
    backgroundColor: "#fff",
    border: "solid 5px #D1833E",
    padding: "7px 45px",
    fontSize: "1.7rem",
    fontWeight: 300,
    letterSpacing: "4px",
    textTransform: "uppercase",
  },
  videoHolder: {
    position: "relative",
    width: "100%",
    marginTop: "5px",
    paddingBottom: "56%",
    height: "0",
    overflow: "hidden",
  },
  videoThumbnail: {
    width: "130%",
    marginLeft: "-15%",
    height: "auto",
  },
  videoPlay: {
    position: "absolute",
    top: "10%",
    bottom: "10%",
    left: "10%",
    right: "10%",
    width: "100px",
    margin: "auto",
  }, 
});

class PreWatch extends React.Component {
  constructor(props) {
    super(props);
    this.onCompletePreWatch = this.onCompletePreWatch.bind(this);
    this.onZoomClick = this.onZoomClick.bind(this);
  }

  onCompletePreWatch = event => {
    if(event) {
      event.preventDefault();
    }
    completePreWatch();
    this.props.onCompletePreWatch();
  };

  onZoomClick = event => {
    if(event) {
      // No op
    }
  };

  render() {
    const { classes } = this.props;
    if(!this.props.shouldShow) {
      return "";
    } else {
      return (
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={10} md={8} lg={8}>
                <div className={classes.step}>First, click the PLAY button below to watch a quick welcome video to prepare for the retreat.</div>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={10} md={8} lg={8}>
                <StaticQuery query={query} render={data => (
                  <VimeoEmbed title={data.contentfulPresidentsRetreatResource.title} vimeoId={data.contentfulPresidentsRetreatResource.vimeoId}></VimeoEmbed>
                )} />
              </GridItem>
            </GridContainer>
            <Box className={classes.caption} pb={2}>
              To make this video full screen, click the <ZoomOutMapIcon className={classes.fullScreenIcon} /> button in the lower right corner.
            </Box>

            <GridContainer justify="center" alignItems="center">
              <GridItem xs={12} sm={10} md={8} lg={8}>
                <div className={classes.step}>Then, click the ZOOM button below to prepare and test your device for our Zoom Room discussions that will happen after each evening's sessions.</div>
              </GridItem>
              <GridItem xs={12}>
                <div className={classes.zoomButtonHolder}>
                  <a href="https://zoom.us/test" target="__blank" className={classes.zoomButton} onClick={this.onZoomClick}><img src={zoomButton} alt="Zoom" height="100" width="100" /></a>
                </div>
              </GridItem>              
            </GridContainer>

            <GridContainer justify="center" alignItems="center">
              <GridItem xs={12} sm={10} md={8} lg={8}>
                <div className={classes.step}>Finally, click the button below to enter the President's Retreat Online. You're ready to go!</div>
              </GridItem>
              <GridItem xs={12}>
                <div className={classes.buttonHolder}>
                  <Button className={classes.button} size="lg" onClick={this.onCompletePreWatch}>Enter</Button>
                </div>
              </GridItem>              
            </GridContainer>
            <NeedHelp />
          </div>
      );
    }
  }
}

PreWatch.propTypes = {
  classes: PropTypes.object.isRequired,
  shouldShow: PropTypes.bool.isRequired,
  onCompletePreWatch: PropTypes.func
};

export default withStyles(pageStyle)(PreWatch);

export const query = graphql`
  query {
    contentfulPresidentsRetreatResource(tags: {in: "Check In"}) {
      title
      vimeoId
    }
  }
`
