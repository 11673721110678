import React from "react";
import PropTypes from "prop-types";
import { Box, SvgIcon, withStyles } from "@material-ui/core";
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import { getUser } from "../../services/auth";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import zoomButton from "assets/img/zoom-button.png";

import { container } from "assets/jss/material-kit-pro-react.js";
import BoxcastEmbed from "../BoxcastEmbed/BoxcastEmbed";
import NeedHelp from "./NeedHelp";
import Title from "./Title";
import RumbleTalkEmbed from "../RumbleTalkEmbed/RumbleTalkEmbed";

const pageStyle = theme => ({
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    textAlign: "center",
    lineHeight: "1.9rem",
    fontWeight: 200,
    ...container
  },
  title: {
    fontFamily: "Merriweather",
    fontSize: "1.9rem",
    fontWeight: 700,
    textTransform: "uppercase",
    margin: "5px 0 15px 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.4rem",
      lineHeight: "1.8rem",
    }
  },
  titleLetter: {
    fontSize: "2.75rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.0rem",
    }
  },  
  step: {
    fontSize: "1.3rem",
    fontWeight: 400,
    margin: "10px 0 10px 0",
    "&:first-line": {
      color: "#D1833E",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.4rem",
      lineHeight: "1.8rem",
    }
  },
  timesImage: {
    margin: "20px auto 20px auto",
    maxWidth: "600px"
  },
  themeLogo: {
    margin: "30px 0 25px 0"
  },
  fourAnchors: {
    margin: "0 0 30px 0"
  },
  caption: {
    fontSize: "1.0rem",
    fontWeight: 400,
    paddingTop: "5px",
  },
  fullScreenIcon: {
    color: "#ffffff",
    marginLeft: "5px",
    marginBottom: "-5px"
  },
  zoomButtonHolder: {
    margin: "0 0 50px 0",
    textAlign: "center"
  },
  zoomButton: {
    color: "#fff !important",
    backgroundColor: "transparent",
    border: "none",
    padding: "0",
    margin: "0",
    fontSize: "1.7rem",
    fontWeight: 300,
    letterSpacing: "4px",
    textTransform: "uppercase",
    boxShadow: "none",
    "&:hover,&:focus,&:visited": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  chatHolder: {
    width: "100%",
    height: "500px",
    "& iframe": {
      height: "500px !important"
    },
    margin: "20px 0 20px 0",
  }
});

class Watch extends React.Component {
  constructor(props) {
    super(props);
    this.state = { unlock: 0 };
    this.onZoomClick = this.onZoomClick.bind(this);
  }

  onUnlockClick = event => {
    if(event) {
      event.preventDefault();
    }
//    this.setState({ unlock: this.state.unlock + 1 });
    return false;
  };

  determineZoomLink() {
    let now = new Date();
    let nearSession1 = (now.getTime() >= (new Date("2020-10-16T00:00:00.000-05:00")).getTime() && now.getTime() < (new Date("2020-10-17T00:00:00.000-05:00")).getTime());
    let nearSession2 = (now.getTime() >= (new Date("2020-10-17T00:00:00.000-05:00")).getTime() && now.getTime() < (new Date("2020-10-18T00:00:00.000-05:00")).getTime());
    let nearSession3 = (now.getTime() >= (new Date("2020-10-18T00:00:00.000-05:00")).getTime() && now.getTime() < (new Date("2020-10-19T00:00:00.000-05:00")).getTime());
    if(nearSession1) {
      return "https://us02web.zoom.us/j/84587916706?pwd=NjkydmhnUEZrY0dFRXQ1UVNtVU1DQT09";
    }
    if(nearSession2) {
      return "https://us02web.zoom.us/j/82940456305?pwd=di9YUDNPMVJDWmtjN1BmdXZYcllsQT09";
    }
    if(nearSession3) {
      return "https://us02web.zoom.us/j/83278727713?pwd=WEtQZEtZR3JkWWZqL0llNWVLSGFGQT09";
    }
    return "https://zoom.us/test";
  }

  determineChannelId() {
    let now = new Date();
    let nearSession1 = (now.getTime() >= (new Date("2020-10-16T00:00:00.000-05:00")).getTime() && now.getTime() < (new Date("2020-10-17T00:00:00.000-05:00")).getTime());
    let nearSession2 = (now.getTime() >= (new Date("2020-10-17T00:00:00.000-05:00")).getTime() && now.getTime() < (new Date("2020-10-18T00:00:00.000-05:00")).getTime());
    let nearSession3 = (now.getTime() >= (new Date("2020-10-18T00:00:00.000-05:00")).getTime() && now.getTime() < (new Date("2020-10-19T00:00:00.000-05:00")).getTime());
    if(nearSession1) {
      return "presidents-retreat-online-131470";
    }
    if(nearSession2) {
      return "presidents-retreat-night-2-422310";
    }
    if(nearSession3) {
      return "presidents-retreat-night-3-897158";
    }
    return "presidents-retreat-online-131470";
  }

  onZoomClick = event => {
    let now = new Date();
    let afterSession1 = (now.getTime() >= (new Date("2020-10-16T20:00:00.000-05:00")).getTime() && now.getTime() < (new Date("2020-10-16T23:00:00.000-05:00")).getTime());
    let afterSession2 = (now.getTime() >= (new Date("2020-10-17T20:00:00.000-05:00")).getTime() && now.getTime() < (new Date("2020-10-17T23:00:00.000-05:00")).getTime());
    let afterSession3 = (now.getTime() >= (new Date("2020-10-18T20:00:00.000-05:00")).getTime() && now.getTime() < (new Date("2020-10-18T23:00:00.000-05:00")).getTime());

    let user = getUser();
    if(user && (afterSession1 || afterSession2 || afterSession3)) {
      trackCustomEvent({
        category: "PR2020",
        action: "Join Zoom",
        label: user.names
      });
    } else {
      if(event) {
        event.preventDefault();
      }
      alert('Please wait to click the Zoom link until directed by the host.')
    }
  };

  render() {
    const { classes } = this.props;
//    let now = new Date();
    let showChat = false;// this.state.unlock >= 3 ? true : (now.getTime() >= (new Date("2020-10-16T00:00:00.000-05:00")).getTime());
    let showStream = false;

    if(!this.props.shouldShow) {
      return "";
    } else {
      return (
        <StaticQuery query={query} render={data => (
          <div className={classes.container}>
            <Title />
            { showStream == true && <div>
              <GridContainer justify="center">
                <GridItem xs={12} sm={10} md={8} lg={8}>
                  <BoxcastEmbed channelId={this.determineChannelId()} />
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} sm={10} md={8} lg={8}>
                  <Box className={classes.caption} pb={2}>
                    To make this video full screen, click the
                    <SvgIcon className={classes.fullScreenIcon} viewBox="0 0 16 16">
                      <path d="M7.156 8L4 11.156V8.5H3V13h4.5v-1H4.844L8 8.844 7.156 8zM8.5 3v1h2.657L8 7.157 8.846 8 12 4.844V7.5h1V3H8.5z"></path>
                    </SvgIcon> button in the lower right corner.
                    <br/>
                    If the live streaming video above does not automatically start at the end of the countdown, click the PLAY button above.
                  </Box>
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} sm={10} md={8} lg={8}>
                  {showChat && <div className={classes.chatHolder}><RumbleTalkEmbed useFloating={false} /></div>}
                </GridItem>              
              </GridContainer>
            </div>}
            <GridContainer justify="center">
              <GridItem xs={12}>
                <Img className={classes.timesImage} alt="" fluid={data.timesImage.childImageSharp.fluid} />
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={10} sm={9} md={6} lg={5}>
                <Img className={classes.themeLogo} alt="" fluid={data.themeLogo.childImageSharp.fluid} />
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={10} md={7} lg={6}>
                <a href="#top" aria-label="Top" onClick={this.onUnlockClick}><Img className={classes.fourAnchors} alt="" fluid={data.fourAnchors.childImageSharp.fluid} /></a>
              </GridItem>
            </GridContainer>

            { showStream == true && <div>
              <GridContainer justify="center" alignItems="center">
                <GridItem xs={12} sm={10} md={8} lg={8}>
                  <div className={classes.step}>After joining the session above, 
                    you'll be directed to click the Zoom link below to join a discussion time together.
                    Please wait to click the link below until directed by the host.</div>
                </GridItem>
                <GridItem xs={12}>
                  <div className={classes.zoomButtonHolder}>
                    <a href={this.determineZoomLink()} target="__blank" className={classes.zoomButton} onClick={this.onZoomClick}><img src={zoomButton} alt="Zoom" height="100" width="100" /></a>
                  </div>
                </GridItem>
              </GridContainer>
            </div>}

            <NeedHelp />
          </div>
        )} />
      );
    }
  }
}

Watch.propTypes = {
  classes: PropTypes.object.isRequired,
  shouldShow: PropTypes.bool.isRequired,
  onRedoCheckIn: PropTypes.func
};

export default withStyles(pageStyle)(Watch);

export const query = graphql`
  query {
    themeLogo: file(relativePath: { eq: "theme-logo.png" }, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fourAnchors: file(relativePath: { eq: "four-anchors-stripe.png" }, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    timesImage: file(relativePath: { eq: "times.png" }, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
