import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import classNames from "classnames";

const componentStyle = theme => ({
  wrapper: {
    padding: "0 20px 0 20px",
    fontSize: "1.4rem",
    fontWeight: 300,
    color: "#ffffff",
    textAlign: "center"
  },
  link: {
    textDecoration: "none",
    color: "#ffffff",
    "&:hover,&:focus,&:visited": {
      color: "#ffffff",
    }
  }
});

class NeedHelp extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classNames(classes.wrapper, this.props.className)}>
        <strong>Have questions? Need technical help?</strong> Contact us at <a className={classes.link} href="tel:18777411200"><strong>877-741-1200</strong></a> or email: <a className={classes.link} href="mailto:lwelch@hutchcraft.com"><strong>lwelch@hutchcraft.com</strong></a>
      </div>
    );
  }
}

NeedHelp.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default withStyles(componentStyle)(NeedHelp);

