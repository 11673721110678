import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames";
import { withStyles } from "@material-ui/core";

const componentStyle = theme => ({
  responsiveVideo: {
    position: "relative",
    display: "block",
    width: "100%",
    padding: "0",
    overflow: "hidden"
  },
  aspect241by100: {
    paddingTop: "41.49378%"
  },
  aspect16by9: {
    paddingTop: "56.25%"
  },
  iframe: {
    position: "absolute",
    top: "0",
    left: "0",
    bottom: "0",
    right: "0",
    width: "100%",
    height: "100%",
  },
  comingSoonHolder: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "#ffffff",
  },
  comingSoon: {
    fontSize: "25px",
    fontWeight: 600,
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    left: 0,
    right: 0,
    color: "#D1833E"
  }
});

class VimeoEmbed extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classNames(classes.responsiveVideo, this.props.useCinema ? classes.aspect241by100 : classes.aspect16by9)}>
        {this.props.vimeoId &&
          <iframe className={classes.iframe} title={this.props.title} src={"https://player.vimeo.com/video/" + this.props.vimeoId} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
        }
        {!this.props.vimeoId &&
          <div className={classes.comingSoonHolder}><div className={classes.comingSoon}>COMING SOON...</div></div>
        }
      </div>
    )
  }
}

VimeoEmbed.propTypes = {
  title: PropTypes.string,
  vimeoId: PropTypes.string,
  useCinema: PropTypes.bool,
}

export default withStyles(componentStyle)(VimeoEmbed);
