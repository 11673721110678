import React from "react";
import PropTypes from "prop-types";
import { loadScriptPromise } from "../../services/utility";
import { getUser } from "../../services/auth";

const QueueName = 'rtmq';

export default class RumbleTalkEmbed extends React.Component {

  componentDidMount() {
    window['rumbleTalkMessageQueueName'] = QueueName;
    window[QueueName] = window[QueueName] || function(){(window[QueueName].q = window[QueueName].q || []).push(arguments)};
    loadScriptPromise("https://rumbletalk.com/client/?Wq1oR6S1", "RumbleTalkEmbedScript").then(() => {
      loadScriptPromise("https://d1pfint8izqszg.cloudfront.net/api/v1.0.0/sdk.js", "RtmqRumbleTalkEmbedScript").then(() => {
        let user = getUser();
        if(typeof window.rtmq !== 'undefined') {
          window.rtmq('login', { hash: 'Wq1oR6S1', username: user.names });
          window.rtmq('logoutCB', { hash: 'Wq1oR6S1', callback: () => { window.rtmq('login', { hash: 'Wq1oR6S1', username: user.names }) } });
        }
      });
    });
  }

  componentWillUnmount() {
  }

  render() {
    if(!this.props.useFloating) {
      return (
        <div><div id="rt-b1412bb506609fbd5b1b6d7f3b6bd926"></div></div>
      );  
    } else {
      return (
        <div id="rt-b1412bb506609fbd5b1b6d7f3b6bd926" data-floating="true" data-side="right" data-width="400" data-height="500" data-counter="14:23"></div>
      );  
    }
  }
}

RumbleTalkEmbed.propTypes = {
  useFloating: PropTypes.bool.isRequired,
};
