import React from "react";
import { withStyles } from "@material-ui/core";

import Header from "components/Header/Header";
import HeaderLinks from "components/Header/HeaderLinks.js";

import { isLoggedIn, isPreWatchComplete } from "../services/auth";
import SEO from "../components/seo"
import Welcome from "../components/SubPages/Welcome";
import CheckIn from "../components/SubPages/CheckIn";
import Watch from "../components/SubPages/Watch";

import "assets/scss/material-kit-pro-react.scss?v=1.9.0";
import PreWatch from "../components/SubPages/PreWatch";

const pageStyle = theme => ({
  background: {
    position: "fixed",
    left: "0",
    right: "0",
    top: "0",
    bottom: "0",
    zIndex: "1",
    overflow: "visible",
    backgroundImage: "url(" + require("../assets/img/background.gif") + ")",
    backgroundPosition: "center", //"0% 50%"
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "scroll",
    backgroundSize: "cover",    
  },
  main: {
    zIndex: "2",
//    height: "100vh",
    paddingTop: "5vh",
//    minHeight: "750px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "140px",
    }
  },
  sections: {
    zIndex: "20",
    display: "flex",
    justifyContent: "center",
    paddingBottom: "40px"
  },
});

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { step: 1 };
    this.onShowCheckIn = this.onShowCheckIn.bind(this);
    this.onCompletedCheckedIn = this.onCompletedCheckedIn.bind(this);
    this.onCompletePreWatch = this.onCompletePreWatch.bind(this);
    this.onRedoCheckIn = this.onRedoCheckIn.bind(this);
  }
  
  componentDidMount() {
    this.setState({ step: isLoggedIn() ? (isPreWatchComplete() ? 4 : 3) : 1 });
  }

  onShowCheckIn = () => {
    this.setState({ step: 2 });
  };

  onCompletedCheckedIn = () => {
    this.setState({ step: isLoggedIn() ? 3 : 1 });
  };

  onCompletePreWatch = () => {
    this.setState({ step: 4 });
  };

  onRedoCheckIn = () => { //Deprecated
    this.setState({ step: 2 });
  };

  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <SEO title="President's Retreat 2020" />
        <Header
          links={this.state.step === 4 ? <HeaderLinks /> : undefined}
//          fixed
          color="transparent"
//          changeColorOnScroll={{ height: 0, color: "white" }}
          {...rest}
        />
        <div className={classes.background}></div>
        <div className={classes.main}>
          <div className={classes.sections}>
            <Welcome shouldShow={this.state.step === 1} onShowCheckIn={this.onShowCheckIn} />
            <CheckIn shouldShow={this.state.step === 2} onCompletedCheckedIn={this.onCompletedCheckedIn} />
            <PreWatch shouldShow={this.state.step === 3} onCompletePreWatch={this.onCompletePreWatch} />
            <Watch shouldShow={this.state.step === 4} onRedoCheckIn={this.onRedoCheckIn} />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(pageStyle)(IndexPage);
