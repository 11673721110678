import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";

import { handleLogin } from "../../services/auth";
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import Face from "@material-ui/icons/Face";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import { container, cardTitle, whiteColor, grayColor} from "assets/jss/material-kit-pro-react.js";
import NeedHelp from "./NeedHelp";

const pageStyle = theme => ({
  cardTitle: {
    ...cardTitle,
    color: whiteColor + "  !important"
  },
  container: {
    ...container,
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px"
    }
  }, 
  form: {
    margin: "0",
    paddingBottom: "20px",
  },
  cardHeader: {
    width: "auto",
    textAlign: "center",
  },
  inputIconsColor: {
    color: grayColor[13]
  },
  textCenter: {
    marginTop: "15px",
    textAlign: "center"
  },
  marginTop: {
    marginTop: "75px"
  }
});

class CheckIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = { code: null, validCode: true, names: null, validNames: true };
    this.onChangeCode = this.onChangeCode.bind(this);
    this.onChangeNames = this.onChangeNames.bind(this);
    this.checkIn = this.checkIn.bind(this);
  }

  onChangeCode = event => {
    if(event && event.target && event.target.value) {
      this.setState({ code: event.target.value, validCode: true });
    } else {
      this.setState({ code: null, validCode: false });
    }
  };

  onChangeNames = event => {
    if(event && event.target && event.target.value) {
      this.setState({ names: event.target.value, validNames: true });
    } else {
      this.setState({ names: null, validNames: false });
    }
  };

  checkIn = event => {
    if(event) {
      event.preventDefault();
    }
    if(!this.state.code) { this.setState({ validCode: false })}
    if(!this.state.names) { this.setState({ validNames: false })}
    if(this.state.code && this.state.validCode && this.state.names && this.state.validNames) {
      if(handleLogin({ names: this.state.names, password: this.state.code })) {
        this.props.onCompletedCheckedIn();
        trackCustomEvent({
          category: "PR2020",
          action: "Check In",
          label: this.state.names
        });
      } else {
        this.setState({ validCode: false});
      }
    }
  };

  render() {
    const { classes } = this.props;
    if(!this.props.shouldShow) {
      return "";
    } else {
      return (
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={7}>
              <Card>
                <form className={classes.form} onSubmit={event => { this.checkIn(event) }}>
                  <CardHeader
                    color="primary"
                    signup
                    className={classes.cardHeader}
                  >
                    <h4 className={classes.cardTitle}>Check In</h4>
                  </CardHeader>
                  <CardBody signup>          
                    <CustomInput
                      id="names"
                      formControlProps={{
                        fullWidth: true
                      }}
                      success={(this.state.validNames && this.state.names && this.state.names.length > 3)}
                      error={!this.state.validNames}
                      inputProps={{
                        placeholder: "Enter Your Name(s)...",
                        type: "text",
                        onChange: this.onChangeNames,
                        startAdornment: (
                          <InputAdornment position="start">
                            <Face className={classes.inputIconsColor} />
                          </InputAdornment>
                        )
                      }}
                    />
                    <CustomInput
                      id="passcode"
                      formControlProps={{
                        fullWidth: true
                      }}
                      success={(this.state.validCode && this.state.code && this.state.code.length >= 7)}
                      error={!this.state.validCode}
                      labelText={!this.state.validCode ? (this.state.code ? "Invalid passcode" : "Please enter your passcode") : ""}
                      inputProps={{
                        placeholder: "Enter Your Passcode",
                        type: "text",
                        onChange: this.onChangeCode,
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockOutlinedIcon className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                        autoComplete: "off"
                      }}
                    />                    
                  </CardBody>
                  <div className={classes.textCenter}>
                    <Button type="submit" color="primary" size="lg">
                      Finish Check In
                    </Button>
                  </div>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
          <NeedHelp className={classes.marginTop} />
        </div>
      );
    }
  }
}

CheckIn.propTypes = {
  classes: PropTypes.object.isRequired,
  shouldShow: PropTypes.bool.isRequired,
  onCompletedCheckedIn: PropTypes.func
};

export default withStyles(pageStyle)(CheckIn);
